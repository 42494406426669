<template>
  <v-row v-if="platform === 'whatsapp'" style="margin: 0 auto">
    <v-col cols="4">
      <v-col cols="12" class="gap-5">
        <v-btn @click="sendToUserHandler" style="margin-bottom: 15px"> Send Template </v-btn>
        <v-badge class="" dot :color="is_scheduled ? 'error' : 'success'">
          <v-icon title="schedule message" @click="scheduleDialog = true" icon="mdi-clock"></v-icon>
        </v-badge>
      </v-col>
      <v-select update:modelValue="true" clearable :menu-props="{ maxHeight: 'unset' }" chips label="template"
        :items="Object.keys(this.templates)" v-model="template" @update:modelValue="templateChanged"></v-select>
      <v-select update:modelValue="true" @update:modelValue="usersAddedFromHandler" clearable chips
        label="Users Added From" :items="Object.keys(this.usersFrom)" v-model="userFrom" :disabled="isAddedFromDisabled"></v-select>
      
      <v-select
        v-model="campaigns"
        @update:modelValue="usersCampaignsHandler" clearable chips
        label="Campaigns"
        :items="campaignsItems"
        multiple
        :disabled="isCampaignsDisabled"
      ></v-select>

      <v-autocomplete v-if="currentUsersList.length < 10000" style="overflow-y: auto; min-height: 100px; max-height: 300px" v-model="select" :items="currentUsersList"
        item-title="name" item-value="id" label="Select user(s)" multiple>
        <template v-slot:prepend-item>
          <v-list-item title="Select All" @click="toggle">
            <template v-slot:prepend>
              <v-checkbox-btn :color="likesSomeUsers ? 'indigo-darken-4' : undefined"
                :indeterminate="likesSomeUsers && !likesAllUsers" :model-value="likesSomeUsers"></v-checkbox-btn>
            </template>
          </v-list-item>
        </template>
      </v-autocomplete>


      <div v-if="currentUsersList.length >= 10000">
        <v-checkbox
        label="Select All"
        
          @click="toggle"
        ></v-checkbox>
        <RecycleScroller
          :items="currentUsersList"
          :item-size="40"
          item-height="400"
          style="overflow-y: auto; min-height: 100px; max-height: 300px"
          v-slot="{ item }"
        >
          <v-list-item style="overflow-y: auto; min-height: 100px; max-height: 300px" :key="item.id">
            <template v-slot:prepend>
              <v-checkbox
                v-model="select"
                :value="item"
                :color="likesSomeUsers ? 'indigo-darken-4' : undefined"
              ></v-checkbox>
            </template>
            <v-list-item-content>{{ item.name }}</v-list-item-content>
          </v-list-item>
        </RecycleScroller>
      </div>

      <div>
        Number of users: {{ currentUsersList.length }},
        <!-- <template> -->
        <div>
          <span>Selected</span>
          <v-progress-linear v-model="select.length" :max="currentUsersList.length" color="blue-grey" height="25">
            <!-- <template v-slot:default="{ value }"> -->
            <strong>{{ select.length }}</strong>
            <!-- </template> -->
          </v-progress-linear>
        </div>
        <!-- </template> -->
      </div>
    </v-col>

    <v-col cols="8">
      <div class="template-container products-container" v-if="template == 'sample_test' && products.length > 0">
        <v-card class="mx-auto" max-width="344" v-for="product in products" :key="product.id">
          <v-img :src="product.thumbnail" height="100px" cover></v-img>

          <v-card-title>
            {{ product.title }}
          </v-card-title>

          <v-card-subtitle>
            {{ product.price }}
          </v-card-subtitle>

          <v-card-text>
            {{ product.brand }}
          </v-card-text>
          <v-checkbox v-model="selected" label="select" :value="product"></v-checkbox>
        </v-card>
        <hr />
      </div>
      <div class="template-container" v-else-if="templatesKeys.includes(template)">
        <v-card class="mx-auto">
          <template
            v-if="!templates[template]['isAssetsNedded'] || templates[template]['assets']['path'] || templates[template]['assets']['latitude']">
            <template v-for="(component, idx) in templates[template]['components']" :key="idx">
              <template v-if="component.type === 'HEADER'">
                <v-img v-if="component.format === 'IMAGE'" :src="templates[template]['isAssetsNedded'] && templates[template]['assets']
                  ? templates[template]['assets']['path']
                  : ''
                  " height="100px"></v-img>

                <video v-else-if="component.format === 'VIDEO'" width="320" height="240" controls>
                  <source :src="component.example.header_handle[0]" type="video/mp4" />

                  Your browser does not support the video tag.
                </video>
                <template v-else-if="component.format === 'LOCATION'">
                  <div class="text-center">
                    <h3>{{ templates[template]['assets']['name'] }}</h3>
                    <p>{{ templates[template]['assets']['address'] }}</p>
                  </div>


                </template>
                <v-card-title v-else-if="component.format === 'TEXT'">
                  {{ component.text }}
                </v-card-title>
              </template>

              <template v-if="component.type === 'BODY'">
                <v-card-text>
                  {{ component.text }}
                </v-card-text>
              </template>
              <hr />
              <template v-if="component.type === 'HEADER'">
                <div v-if="component.example?.header_handle">
                  <p>Header - Variables</p>

                  <div style="
                      display: flex;
                      justify-content: center;
                      /* align-items: center; */
                      flex-direction: column;
                      gap: 10px;
                      border: 1px solid #ccc;
                      padding: 10px;
                    ">
                    <lable :for="component.format">Header - {{ component.format }}</lable>
                    <input disabled style="height: 50px; outline: 0; border: 1px solid #ddd; padding: 8px" type="text"
                      :name="component.format" :id="component.format" :value="templates[template]['assets']['path']"
                      @input="handleHeader(component)" />
                    <p class="text-danger" v-if="headerErrors > 0">
                      {{ headerErrors[0] }}
                    </p>
                  </div>
                </div>
              </template>
              <hr />

              <template v-if="component.type === 'BODY' && component.example?.body_text">
                <div style="height: 350px; overflow-y: auto">
                  <p>Body - Variables</p>
                  <template v-for="(item, idx) in component.example.body_text[0]" :key="idx">
                    <div style="
                        display: flex;
                        justify-content: center;
                        /* align-items: center; */
                        flex-direction: column;
                        gap: 10px;
                        border: 1px solid #ccc;
                        padding: 10px;
                      ">
                      <lable :for="idx">Variable - {{ idx + 1 }}</lable>
                      <input style="height: 50px; outline: 0; border: 1px solid #ddd; padding: 8px" type="text"
                        :name="idx" :id="idx" :value="item" @input="handleVariables(idx, { component })" />
                      <p class="text-danger" color="#F00">{{ errors[idx] }}</p>
                    </div>
                  </template>
                </div>
              </template>
            </template>
          </template>
          <template v-else>
            <p class="text-danger">*Please upload template assets first [ from settings ]</p>
          </template>
        </v-card>
      </div>
      <div v-else>
        <p>no template</p>
      </div>
    </v-col>


    <template>
      <div class="text-center">
        <template>
          <v-row justify="center">
            <v-dialog v-model="scheduleDialog" persistent width="1024">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Select Time to schdule your message</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="12">

                        <input id="party" type="datetime-local" name="schedule at" :min="scheduleAt"
                          v-model="scheduleAt" />

                      </v-col>
                    </v-row>
                  </v-container>

                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue-darken-1" variant="text" @click="scheduleDialog = false"> Close </v-btn>
                  <v-btn color="blue-darken-1" variant="text"
                    @click="scheduleDialog = false; is_scheduled = false; scheduleAt = momentTZ().format('YYYY-MM-DDTHH:mm')">
                    Reset </v-btn>

                  <v-btn color="blue-darken-1" variant="text"
                    @keypress.enter="scheduleDialog = false; is_scheduled = true"
                    @click="scheduleDialog = false; is_scheduled = true"> Schedule </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
      </div>
    </template>
  </v-row>

  <div v-else>
    <p>Instgram not implemented</p>
  </div>
</template>

<script>
// import { vueVirtualScroller } from 'vue-virtual-scroller';
import { RecycleScroller } from 'vue-virtual-scroller'
// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import repository from '@/api/repository'
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '../stores/user'
import { useTemplateStore } from '../stores/template'
import { useGlobalStore } from '../stores/global'
import { v4 as uuidv4 } from 'uuid';
import momentTZ from 'moment-timezone';

export default {
  name: 'TemplatesView',
  // components: {},
  components: {
    // vueVirtualScroller,
    RecycleScroller
  },
  data: () => ({
    picker: '2018-03-02',
    dates: [],
    menu: false,
    url: '',
    selected: [],
    // products: [],
    scheduleDialog: false,
    scheduleAt: momentTZ().format('YYYY-MM-DDTHH:mm'),
    is_scheduled: false,
    select: [],
    items: [],
    isErrorToSend: true,
    show: false,
    probrities: ['id', 'title', 'price', 'thumbnail', 'image', 'brand'],
    template: null,
    templatesData: [],
    variables: [],
    header: [],
    language: null,
    errors: [],
    headerErrors: [],
    excelUsers: [],
    platform: 'whatsapp',
    usersAddedFrom: ['all', 'excel_sheet', 'url', 'api', 'conversation'],
    usersFrom: {
      all: [],
      excel_sheet: [],
      url: [],
      api: [],
      conversation: [],
    },
    campaigns: [],
    campaignsItems: ['30-days-without-orders'],
    userFrom: null,
    currentUsersList: [],
    isAddedFromDisabled: false,
    isCampaignsDisabled: false,
  }),
  computed: {
    ...mapState(useUserStore, [
      'userList',
      'user',
      'admin',
      'filterUsers',
      'instagramUsers',
      'whatsappUsers',
      'gerUsersAddedFrom',
    ]),
    ...mapState(useTemplateStore, ['templates', 'products', 'templatesKeys']),
    likesAllUsers() {

      // return this.select.length === this.gerUsersAddedFrom(this.userFrom).length
      return this.select.length === this.currentUsersList.length

      // this.currentUsersList
    },
    likesSomeUsers() {
      return this.select.length > 0
    },
  },
  methods: {
    ...mapActions(useUserStore, ['getUsers','gerUsersFrom', 'sendTemplateMessage', 'getAdmin']),
    ...mapActions(useTemplateStore, ['getTemplates']),
    ...mapActions(useGlobalStore, ['setAlertMessage']),

    handleHeader(component) {
      console.log({
        com: component.format,
        header: this.header,
      })
      component.example.header_handle[0] = event.target.value

      let format = component.format.toLowerCase()
      // this.header = [event.target.value]
      this.header[0][format]['id'] = event.target.value
      console.log({
        v: [event.target.value],
        // component,
        header: this.header,
      })
    },
    handleVariables(idx) {
      this.variables[idx] = event.target.value
      // console.log(event.target.value, idx, component, `{{${idx+1}}}`);
      // component.component.text = component.component.text.replace(`{{${idx+1}}}`, event.target.value);
      console.log(this.variables)
    },

    templateChanged(value) {
      this.variables = []
      this.header = []
      this.language = null
      console.log('template changed - ', value)


      if (value && value !== 'sample_test') {
        const data = this.templates[value]
        this.language = this.templates[value].language
        console.log('template changed - ', { data })

        data['components'].forEach((item) => {
          console.log({
            item,
          })
          if (item.type === 'BODY') {
            if (item?.example?.body_text) {
              this.variables = item?.example?.body_text[0]
              console.log({
                v: this.variables,
              })
            }
          }
          if (item.type === 'HEADER') {
            let format = item.format.toLowerCase();

            if (item.example?.header_handle) {
              this.header.push({
                type: format,
              })

              this.header[0][format] = {
                id: data['assets']['id'],
              }

              if (item.format == 'DOCUMENT') {
                this.header[0][format]['filename'] = 'Document'
              }
            }
            else if (format === 'location') {
              this.header.push({
                type: format,
              })
              this.header[0][format] = data['assets']

            }
          }
          console.log({ header: this.header })
        })
      }
    },
    toggle() {
      console.log('=========================================================================== Toggling')
      if (this.likesAllUsers || this.likesSomeUsers) {
        this.select = []
      } else {
        // this.select = this.gerUsersAddedFrom(this.userFrom)
        console.log({
          currentUsersList: this.currentUsersList
        })
        this.select = this.currentUsersList

        // this.currentUsersList
      }
    },
    async getDataHandler() {
      // this.resetIcon();
      // this.clearMessage();

      const res = await repository.index(`templates`)

      let prepareDate = {}
      this.templatesData = res.data.templates
      this.templates = Object.keys(res.data.templates)
      res.data.products.forEach((product) => {
        this.probrities.forEach((item) => {
          prepareDate[item] = product[item]
        })

        this.products.push(prepareDate)

        prepareDate = {}
      })

      console.log({
        templatesData: this.templatesData,
      })
    },

    async sendToUserHandler() {
      console.log(this.template, this.variables, this.header)

      let isError = false

      if (this.variables.length > 0) {
        this.variables.forEach((variable, idx) => {
          if (variable.length == 0) {
            this.errors[idx] = '* Rquired'
            isError = true
          } else {
            this.errors[idx] = ''
          }
        })
      }
      if (this.header.length > 0) {
        this.header.forEach((h, idx) => {
          if (h.length == 0) {
            this.headerErrors[idx] = '* Rquired'
            isError = true
          } else {
            this.headerErrors[idx] = ''
          }
        })
      }

      console.log({ isError })

      if (!isError) {
        if (['custom_template', 'custom_product', 'sample_test'].includes(this.template)) {
          if (this.selected.length == 0) {
            console.log('1')

            this.setAlertMessage({
              display: true,
              text: 'select product(s)',
              class: 'error',
            })
            // this.$store.commit('SET_NOTIFICATION', {
            //   display: true,
            //   text: 'select product(s)',
            //   class: 'erroe',
            // })
            return false
          }
        }
        if (this.template && this.select.length > 0) {

          console.log(this.template, this.select, this.selected)
          let payload = {
            client_id: uuidv4(),
            content: this.template + ' template message',
            platform: this.platform,
            type: this.template.replace(`_${this.language}`, ''),
            seen: false,
            messageType: 'template',
            from: this.admin['id'],
            status: 'pending',
            to: this.to,
            products: this.selected,
            users: this.select,
            variables: this.variables,
            header: this.header,
            language: this.language,
            createdAt: new Date(),
            scheduleAt: this.is_scheduled ? momentTZ(this.scheduleAt).utc().format('YYYY-MM-DDTHH:mm:ss[Z]') : null,
            is_scheduled: this.is_scheduled,
          }

          console.log({
            length: this.select.length,
          })

          const chunkSize = 300
          for (let idx = 0; idx < this.select.length; idx += chunkSize) {
            const chunk = this.select.slice(idx, idx + chunkSize)
            console.log({
              chunkLength: chunk.length,
            })
            payload.users = chunk

            this.sendTemplateMessage(payload, this.platform)

            // await this.$store.dispatch('POST_MESSAGE', payload)
            // this.$store.commit('SET_NOTIFICATION', {
            //   display: true,
            //   text: 'Message Send Successfully',
            //   class: 'success',
            // })

            // let userCopy = this.CURRENT_USER;
            // let usersCopy = this.userList

            // await usersCopy['whatsapp'].forEach(async (user) => {
            //   await chunk.forEach((selectedUser) => {
            //     if (user.id === selectedUser) {
            //       if (payload) {
            //         user.messages = [payload]
            //         // if (userCopy) {
            //         //   if (userCopy.id === selectedUser)
            //         //     userCopy.messages.push(payload);
            //         // }
            //       }
            //     }
            //   })
            // })
            // await this.$store.dispatch('UPDATE_USERS', usersCopy)
          }

          this.select = []
          this.selected = []

          if (this.is_scheduled) {
            this.is_scheduled = false;
            this.scheduleAt = momentTZ().format('YYYY-MM-DDTHH:mm')
          }
        } else {
          console.log('2')

          this.setAlertMessage({
            display: true,
            text: 'select user(s) or product(s)',
            class: 'error',
          })
          // this.$store.commit('SET_NOTIFICATION', {
          //   display: true,
          //   text: 'select user(s) or template',
          //   class: 'erroe',
          // })
        }
      } else {
        console.log('3')

        this.setAlertMessage({
          display: true,
          text: 'Variable(s) required',
          class: 'error',
        })
        // this.$store.commit('SET_NOTIFICATION', {
        //   display: true,
        //   text: 'Variable(s) required',
        //   class: 'erroe',
        // })
      }
    },
    async usersAddedFromHandler(value) {
      console.log({ value }, value === null)
      if (value === null) {
        this.select = [];
        this.currentUsersList = [];
        // this.isAddedFromDisabled = false;
        this.isCampaignsDisabled = false;

      } else {
        this.isCampaignsDisabled = true;
        this.currentUsersList = await this.gerUsersFrom(value);
      }
    },

    async usersCampaignsHandler(value) {
      console.log({ value }, value === null)

      if(value.length === 0){
        this.select = [];
        this.currentUsersList = [];
        this.isAddedFromDisabled = false;
      }else{
        this.isAddedFromDisabled = true;
        this.currentUsersList = await this.gerUsersFrom(value, true)
      }
      
      
    },

    saveHandler() {
      console.log('saveHandler', {
        val: this.dates,
      })
    },

    scheduleMsgHandler: function () {
      this.is_scheduled = true;
    },
    cancelScheduleMsgHandler: function () {
      this.is_scheduled = false;
    },
  },

  async mounted() {
    console.log('templates | mounted')
    document.title = 'Templates'
    await this.getAdmin()
    await this.getTemplates()
    // await this.getUsers()
  },
}
</script>

<style scoped>
.template-container {
  height: 80vh;
  overflow-x: auto;
}

/* .products-container {
  height: 350px !important;
  overflow: auto;
} */

.text-danger {
  color: #f44336;
}
</style>
