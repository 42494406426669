<template>
  <div class="settings">
    <div>
      <v-row>
        <v-col class="side-menu text-menu-container" height="84vh"  cols="1" v-if="!showMenuFlag">
          <template class="d-flex justify-end toggle-container">
            <v-icon
              title="Open Menu"
              icon="mdi-arrow-right-circle"
              @click="showSideMenuHandler"
            ></v-icon>
          </template>
          <p class="text-menu">Settings Menu</p>
        </v-col>
        <v-col class="side-menu" v-if="showMenuFlag" xs="3" sm="2" md="2">
          <template class="d-flex justify-end toggle-container">
            <v-icon
              title="Close Menu"
              icon="mdi-arrow-left-circle"
              @click="showSideMenuHandler"
            ></v-icon>
          </template>
          <v-card class="settings-container" max-width="300" height="84vh">
            <!-- <template> -->
           
            <v-list lines="one">
              <v-list-item
                v-for="item in items"
                :key="item.value"
                :title="item.title"
                @click="viewSubSettingPageHandler(item.value)"
                :class="[
                  { 'active-page': item.value == activePage },
                  'page text-center',
                ]"
              >
              </v-list-item>
              <v-list-item v-if="items.length == 0"
                ><p class="text-center">No Settings</p></v-list-item
              >
            </v-list>
            <!-- </template> -->
          </v-card>
        </v-col>
        <v-col :xs="!showMenuFlag ? 11 : 10" :sm="!showMenuFlag ? 11 : 10" :md="!showMenuFlag ? 11 : 10">
          <template v-if="isSubRoute">
            <router-view />
          </template>
          <div  style="display: flex; justify-content: center; align-items: center; height: 100%" v-else>
            <v-container>
              <p class="text-center">Manage Your Settings</p>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style scoped>
.text-menu-container {
  height: 86vh;
  border-right: 1px solid #efe9e9;
}
.text-menu {
  /* margin: auto 0; */
  /* width: 0; */
  white-space: nowrap;
  height: 10px;
  /* position: absolute; */
  text-align: left;
  transform-origin: bottom;
  margin-top: 300px;
  transform: rotate(270deg);
}
.side-menu {
  position: relative;
}
.toggle-container {
  position: absolute;
  top: 30px;
  right: -15px;
  z-index: 2;
}
.settings-container {
  overflow-y: auto !important;
  border-right: 1px solid !important;
}
.page{
  border-bottom: 1px solid #efebeb !important;
}
.active-page,
.page:hover {
  background-color: #e7e7e7 !important;
}
</style>
<script>


export default {
  name: 'HomeSettingView',
  data: () => ({
    showMenuFlag: true,
    isSubRoute: false,
    activePage: null,
    items: [
      {
        title: 'Users',
        value: 'users-setting'
      },
      {
        title: 'Black List',
        value: 'black-list-setting'
      },
      {
        title: 'Wellcome Message',
        value: 'wellcome-message-setting'
      },
      {
        title: 'Instagram Auto Message',
        value: 'auto-message-setting'
      },
      {
        title: 'Image Template',
        value: 'image-templat-setting'
      },
      {
        title: 'Template Assets',
        value: 'template-assets-setting'
      },
      {
        title: 'Campaigns',
        value: 'campaigns-setting'
      }
    ],
  }),
  computed: {
    
  },
  async updated() {
    this.isSubRoute = this.$route.matched.length > 1
    if (!this.isSubRoute) {
      this.activePage = null
    }

    console.log('HomeSettingView | updated', {
      isSubRoute: this.isSubRoute,
      route: this.$route
    })
  },
  async mounted() {
    document.title = 'Settings'
    // if(this.userList){
    //   console.log("Conversations | mounted", 'users already loaded')
    // }else{
    //   this.getUsers();
    // }
    console.log('HomeSettingView | mounted', {
      id: this.$route.params.id,
    })

    this.isSubRoute =  this.isSubRoute = this.$route.matched.length > 1

    if (this.isSubRoute) {
      this.activePage =  this.$route.matched[1]['name']
    }
  },
  methods: {
    viewSubSettingPageHandler: function (pageId) {
      console.log({
        pageId,
      })
      this.activePage = pageId;
      this.$router.push({ name: pageId })
    },
    showSideMenuHandler: function () {
      console.log('showSideMenuHandler', this.showMenuFlag)
      this.showMenuFlag = !this.showMenuFlag
    },
  },
}
</script>

