<template>
  <v-container>
    <v-card class="card-container">
      <v-tabs v-model="tab" color="deep-purple-accent-4" align-tabs="center">
        <v-tab value="one">30-days without orders</v-tab>
      </v-tabs>

      <!-- <v-card-text> -->
      <v-window v-model="tab" class="window-container">
        <v-window-item value="one">
          <v-btn @click="import30DaysCampaignHandler"> import </v-btn>
        </v-window-item>
        <p>Users will be imported: {{usersCount}}</p>
      </v-window>
      <!-- </v-card-text> -->
    </v-card>
  </v-container>
</template>

<style scoped>
.card-container {
  box-shadow: none;
  height: 80vh;
}
.window-container {
  padding: 20px;
}
</style>

<script>
import repository from '../../api/repository'

import * as XLSX from 'xlsx'
import { mapState, mapActions } from 'pinia'
import { useGlobalStore } from '../../stores/global'
import { useUserStore } from '../../stores/user'

export default {
  name: 'CampaignsSettingView',
  components: {},

  data: () => ({
    tab: null,
    dialog: false,
    usersFrom: ['all', 'excel_sheet', 'url', 'api', 'conversation'],
    userFrom: null,
    responseMsgs: [],
    file: null,
    fileInfo: {
      size: 0,
      usersNumber: 0,
    },
    usersCount: 0,
    excelData: [],
  }),
  computed: {
    ...mapState(useGlobalStore, ['notifications']),
  },
  methods: {
    ...mapActions(useGlobalStore, ['setNotify', 'setNotifications', 'appendNotifications']),
    ...mapActions(useUserStore, ['setIsUsersUpdated']),


    async import30DaysCampaignHandler() {
      const res = await repository.campaigns('30-days-without-orders')
      console.log({
        res
      })
      this.setNotifications(res.data.messages)

      this.usersCount = res.data.usersCount
     
      this.setIsUsersUpdated()
      
      this.setNotify()

      console.log({
        res,
      })
    },
    onClear() {
      console.log('onClear')
      this.fileInfo.usersNumber = 0
      this.excelData = []
    },
    async onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null
      if (this.file) {
        console.log({
          wb: this.file,
        })
        this.fileInfo.size = this.file.size
        const reader = new FileReader()

        reader.onload = async (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = XLSX.read(bstr, { type: 'binary' })
          /* Get first worksheet */
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          /* Convert array of arrays */
          this.excelData = XLSX.utils.sheet_to_json(ws, { header: 1 })
          this.excelData.shift()
          console.log({
            data: this.excelData,
            wb: this.file,
          })

          this.excelData.forEach((item) => {
            console.log({ is0: item[0] !== undefined, is1: item[1] !== undefined })
          })
          this.excelData = this.excelData.filter((item) => {
            return item[0] !== undefined && item[1] !== undefined
          })
          this.fileInfo.usersNumber = this.excelData.length
        }
        reader.readAsBinaryString(this.file)
        this.file = null
      }
    },
    async confirmHandler() {
      this.dialog = false
      if (this.userFrom) {
        const res = await repository.create('users/reset', { added_from: this.userFrom })
        // this.responseMsgs.push(res.data.data)
        // await this.$store.dispatch('SET_LOGGER', this.responseMsgs)
        this.setNotifications([res.data.data])
        this.setIsUsersUpdated()
        console.log({ res })
        this.setNotify()
      }
    },
  },

  async updated() {},
  async created() {
    console.log('users | created')
  },
  async mounted() {
    console.log('users')
  },
}
</script>
